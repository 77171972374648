<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="上传司仪稿"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        style="margin-top:30px;"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="司仪稿内容">
          <div
            style="line-height: 20px;margin-top: 10px;margin-bottom: 10px"
          >pdf格式，10MB以内
          </div>
          <upload-custom-file
            accept=".pdf"
            :max-count="1"
            :max-size="10240000"
            @change="handleUploadImage"
            v-decorator="['attachments',{
              rules:[
                { required: true, message: '请上传司仪稿' }
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="是否为最终版本">
          <a-checkbox value="true" v-decorator="['finished', { valuePropName: 'checked' }]">勾选后司仪稿撰写任务标记为已完成</a-checkbox>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UploadCustomFile from '@/components/Upload/CustomFile'
import { uploadEmceeDraft } from '@/api/order'

export default {
  name: 'UploadEmceeDraft',
  components: {
    UploadCustomFile
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'upload_emcee_draft' }),
      submitting: false,
      data: {},
      attachments: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
  },
  methods: {
    handleUploadImage(fileList) {
      this.attachments = fileList
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          uploadEmceeDraft(this.serviceOrderId, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
